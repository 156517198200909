<template>
  <a-modal
      title="编辑任务"
      :visible="visible"
      :width="720"
      :maskClosable="false"
      destroyOnClose
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="标题">
          <a-input
            v-decorator="['title', { rules: [{ required: true, message: '请输入标题' }] }]"
          />
        </a-form-item>

        <a-form-item label="负责人">
           <a-select
                :filter-option="false" 
                show-search
                :allowClear="true"
                placeholder="请选择或搜索负责人"
                v-decorator="['masterId', { rules: [{ required: true, message: '请选择负责人' }] }]"
                @search="handleSearch"
                >
                    <a-select-option v-for="item in masterList" v-bind:key="item.userId" :value="item.userId">
                        {{item.name }}
                    </a-select-option>
            </a-select>
        </a-form-item>

        <a-form-item label="起止时间">
          <a-range-picker style="width:100%" format="YYYY-MM-DD" v-decorator="['timeRange']" @change="dateChange">
                      <a-icon slot="suffixIcon" type="calendar" />
           </a-range-picker>
        </a-form-item>

        <a-form-item label="预估工时">
          <a-input-group compact>
              <a-input-number style="width: 75%" v-decorator="['estimate']" />
              <a-input style="width: 25%" default-value="小时(h)" disabled />
            </a-input-group>
        </a-form-item>

        <a-form-item label="优先级">
          <a-select placeholder="请选择优先级"
                v-decorator="[
                    'level',
                    {rules: [{ message: '请选择优先级'}]}
                  ]">
                <a-select-option value="1">最高</a-select-option>
                <a-select-option value="2">较高</a-select-option>
                <a-select-option value="3">普通</a-select-option>
                <a-select-option value="4">较低</a-select-option>
                <a-select-option value="5">最低</a-select-option>
              </a-select>
        </a-form-item>

        <a-form-item label="描述">
          <j-editor  v-decorator="['content']" triggerChange></j-editor>
        </a-form-item>
      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" :loading="confirmLoading" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
    import moment from 'moment'
    import JEditor from '@/components/Editor/JEditor'
    import { updateTask } from "@/httpsAPI/project/task"
    import {getProjectUserList} from "@/httpsAPI/common";
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                timeRange:[],
                startTime:'',
                endTime:'',
                scrollPage: 1,
                masterData: [],
                keyword: '',
                masterList: [],
                projectId: 0,
                taskId: 0
            }
        },
        components:{
          JEditor
        },
        methods:{
          // 下拉框 分页 搜索
          changeMasterList() {
              getProjectUserList({name: this.keyword,projectId:this.$route.query.projectId,pageNo:1, pageSize: 1000})
                      .then(res => {
                          this.masterList = res.data.data;
                          this.masterData = res.data;
                      })
          },
          //选择渠道下拉框滚动事件
          handlePopupScroll(e) {
              const { target } = e
              const scrollHeight = target.scrollHeight - target.scrollTop
              const clientHeight = target.clientHeight
              // 下拉框不下拉的时候
              if (scrollHeight === 0 && clientHeight === 0) {
                  this.scrollPage = 1
              } else {
                  // 当下拉框滚动条到达底部的时候
                  if (scrollHeight < clientHeight + 5) {
                      this.pageData(1);
                  }else if (scrollHeight == 3208) {
                      this.pageData(2);
                  }
              }
          },
          pageData(type) {
              if (this.masterData.totalPage < this.scrollPage) {
                  this.scrollPage = this.masterData.totalPage;
              } else {
                  type == 1 ? this.scrollPage++ : this.scrollPage--;
                  this.changeMasterList();
                  let newData = [];
                  let max = this.masterData.totalCount;
                  this.masterList.forEach((item, index) => {
                  //当data数组的下标小于max时
                  if (index < max) {
                      newData.push(item)
                  }
                  })
                  this.masterList = newData;
              }
          },
          handleSearch(keyword){
              this.keyword = keyword;
              this.changeMasterList();
          },
          dateChange(date, dateString) {
            this.startTime = dateString[0]
            this.endTime = dateString[1]
          },
          dataFormSubmit(e) {
            this.confirmLoading = true;
            this.form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                this.confirmLoading = true;
                
                values['id'] = this.taskId
                values['projectId'] = this.projectId
                values['taskType'] = 2
                values['startTime'] = this.startTime
                values['endTime'] = this.endTime

                console.log('Received values of form: ', values);
                updateTask(values).then(res=>{
                  this.confirmLoading = false;
                  if(res.code==10000) {
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }
                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (record) {
            console.log(record)
            this.confirmLoading = false
            this.visible = true
            this.projectId = record.projectId
            this.$nextTick(() => { 
                this.form.resetFields();
                this.taskId = record.id
                this.startTime = record.startTime ? moment(record.startTime).format('YYYY-MM-DD') : ''
                this.endTime = record.endTime ? moment(record.endTime).format('YYYY-MM-DD') : ''
                // this.changeMasterList()

                getProjectUserList({name: this.keyword,projectId:this.$route.query.projectId,pageNo:1, pageSize: 1000}).then(res => {
                    this.masterList = res.data.data;
                    this.masterData = res.data;

                    this.form.setFieldsValue({
                      title: record.title,
                      masterId: record.masterId,
                      estimate: record.estimate,
                      level: record.level + '',
                      timeRange: record.startTime ? [moment(this.startTime, "YYYY-MM-DD"), moment(this.endTime, "YYYY-MM-DD")] : [],
                      content: record.content
                    })
                })
                
            });
          }
        }
    }
</script>